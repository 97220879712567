import React from 'react';

const teamMembers = [
    {
        name: 'Radhika Mitra',
        title: 'Founder & CEO',
        image: './Radhika.png',
        description: `Radhika is an entrepreneur at heart, driven by helping companies authentically connect with customers and unlock their market potential. Inspired by Silicon Valley’s culture of innovation, she built a career at the intersection of technology, creative strategy, and growth.
    
    With leadership roles over 10 years in product and growth marketing at AWS, Okta, and Cisco, and degrees in Strategic Management from Harvard Business School and Computer Science from UC Santa Cruz, Radhika blends technical expertise with creative marketing to drive exceptional results.
    
    As the founder of SVM Ventures, she combines skills in marketing, technology, product strategy, and fund management to help B2C and B2B companies thrive.`,
        linkedIn: 'http://linkedin.com/in/rmitra1',
    },
    {
        name: 'Greg Roldan',
        title: 'Director of Business',
        image: './Greg.png',
        description: `Greg leverages over a decade of expertise in business development, marketing strategy, and client relations. His career journey spans diverse roles that have honed his deep understanding of the marketing landscape. A master at spotting market opportunities, Greg forges partnerships rooted in a meaningful growth mindset.`,
        linkedIn: 'http://linkedin.com/in/greg-roldan',
    },
    {
        name: 'Soumya Mitra',
        title: 'Chief of Product & AI Advisor',
        image: './Soumya.png',
        description: `Soumya is a strategic Product and AI Advisor with a strong history of building top-performing products and driving innovation through AI. Bridging the gap between business and technology, Soumya combines deep expertise in AI and market insights to help companies turn ideas into impactful solutions.`,
        linkedIn: 'https://www.linkedin.com/in/mitrasoumya?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
        name: 'Shannon Harris',
        title: 'Director of Customer Growth',
        image: './Shannon.png',
        description: `Shannon Harris has over 15 years of expertise in international finance, investment strategy, and business development. Her investment background includes managing sustainability risk for a $50 billion institutional portfolio, while also specializing in start-up finance and marketing strategies.`,
        linkedIn: 'https://www.linkedin.com/in/shannon-harris-85076b104?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
        name: 'Satyam Tiwari',
        title: 'Design Advisor',
        image: './satyam.png',
        description: `Satyam Tiwari is a seasoned UX and product designer with a strong focus on enhancing user experiences and optimizing product performance. Renowned for his precision and user-centered approach, he specializes in designing intuitive interfaces and seamless interactions. As a Design Advisor at SVM Ventures, Satyam collaborates with cross-functional teams to drive innovation, leveraging industry-leading tools to transform ideas into impactful digital solutions.`,
        linkedIn: 'https://www.linkedin.com/in/satyam-tiwari-965598153/',
    },
    {
        name: 'Sri Ramachandran',
        title: 'Product Marketing Manager',
        image: './Sri.png',
        description: `Sri brings over six years of experience in product marketing, client relationship management, and go-to-market strategies. At SVM Ventures, Sri plays a key role in crafting innovative growth strategies.`,
        linkedIn: 'https://www.linkedin.com/in/sriranjani-ram?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
];

const TeamSection = () => {
    return (
        <div className="container my-5 mobile-view-container" style={{ paddingLeft: "120px", paddingRight: "120px" }}>
            <h2 className="text-center mb-4 fw-bold">Say Hello To The Leaders Of Our Team!</h2>
            <p className="text-center text-muted mb-5">
                Our guiding pillars who support us in everything we do.
            </p>
            <div className="row">
                {teamMembers.map((member, index) => (
                    <div key={index} className="col-12 mb-4">
                        <div className="d-flex gap-4 align-items-center mobile-view-about-us">
                            <img
                                src={member.image}
                                alt={member.name}
                                className="rounded shadow-sm"
                                style={{
                                    width: member.name === "Radhika Mitra" ? "280px" : '300px', height: member.name === "Radhika Mitra" ? "280px" : '300px', objectFit: 'cover'
                                }}
                            />
                            <div>
                                <p style={{ whiteSpace: 'pre-line', fontSize: "16px", color: "#000", fontWeight: "500", textAlign: "left" }}>{member.description}</p>
                                <a href={member.linkedIn} className="text-danger fw-bold" target="_blank">
                                    Visit LinkedIn
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default TeamSection;
